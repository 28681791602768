<template>
  <section class="location-section my-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <figure>
            <img
              class="img-thumbnail"
              src="../assets/location.jpg"
              alt="sirius town location"
            />
          </figure>
        </div>
        <div class="col-lg-6">
          <div>
            <h2>{{ $t("LocationTitle") }}</h2>
            <p>{{ $t("LocationDesc1") }}</p>
            <p>{{ $t("LocationDesc2") }}</p>
            <p>{{ $t("LocationDesc3") }}</p>
            <p>{{ $t("LocationDesc4") }}</p>
          </div>
        </div>
      </div>

      <div class="boxes-side mt-5 text-center">
        <h3>{{ $t("LocationAdvantages") }}</h3>
        <div class="boxes">
          <div class="box">
            <figure>
              <img src="../assets/icons/location-01.png" alt="location" />
            </figure>
            <p>{{ $t("Mall") }}</p>
          </div>
          <div class="box">
            <figure>
              <img src="../assets/icons/location-05.png" alt="location" />
            </figure>
            <p>{{ $t("School") }}</p>
          </div>
          <div class="box">
            <figure>
              <img src="../assets/icons/location-09.png" alt="location" />
            </figure>
            <p>{{ $t("BusStop") }}</p>
          </div>
          <div class="box">
            <figure>
              <img src="../assets/icons/location-07.png" alt="location" />
            </figure>
            <p>{{ $t("Hospital") }}</p>
          </div>
          <div class="box">
            <figure>
              <img src="../assets/icons/location-03.png" alt="location" />
            </figure>
            <p>{{ $t("Restaurants") }}</p>
          </div>
          <div class="box">
            <figure>
              <img src="../assets/icons/location-10.png" alt="location" />
            </figure>
            <p>{{ $t("Sea") }}</p>
          </div>
          <div class="box">
            <figure>
              <img src="../assets/icons/location-04.png" alt="location" />
            </figure>
            <p>{{ $t("Airport") }}</p>
          </div>
          <div class="box">
            <figure>
              <img src="../assets/icons/location-06.png" alt="location" />
            </figure>
            <p>{{ $t("Banks") }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>