<template>
  <section class="services-page">
    <div class="container my-5">
      <b-tabs content-class="mt-1">
        <b-tab title="Spa Center">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <p>
                  {{ $t("SpaDesc1") }}
                </p>
                <ul>
                  <li>{{ $t("TurkishBath") }}</li>
                  <li>{{ $t("SaunaSteam") }}</li>
                  <li>{{ $t("VIPMassageRooms") }}</li>
                  <li>{{ $t("RelaxtionLounge") }}</li>
                </ul>
                <p>
                  {{ $t("SpaDesc2") }}

                  <br />
                  {{ $t("SpaDesc3") }}

                  <br />
                  {{ $t("SpaDesc4") }}
                </p>
              </div>
              <div class="col-lg-6">
                <figure>
                  <img
                    class="img-thumbnail"
                    src="../assets/services/spa-mid.jpg"
                    alt="Spa"
                  />
                </figure>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('FitnessAndWellnes')">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <p>
                  {{ $t("FitnessAndWellnesDesc1") }}
                </p>
                <ul>
                  <li>{{ $t("FitnessCenter") }}</li>
                  <li>{{ $t("CardioCycling") }}</li>
                  <li>{{ $t("NutritionBar") }}</li>
                </ul>
                <p>
                  {{ $t("FitnessAndWellnesDesc2") }}
                </p>
              </div>
              <div class="col-lg-6">
                <figure>
                  <img
                    class="img-thumbnail"
                    src="../assets/services/fitness-mid.jpg"
                    alt="Fitness"
                  />
                </figure>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('Pool')">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <p>{{ $t("PoolDesc1") }}</p>
                <ul>
                  <li>{{ $t("TwoOutdoorPools") }}</li>
                  <li>{{ $t("IndoorPool") }}</li>
                  <li>{{ $t("SunbedArea") }}</li>
                  <li>{{ $t("Aquapark") }}</li>
                </ul>
              </div>
              <div class="col-lg-6">
                <figure>
                  <img
                    class="img-thumbnail"
                    src="../assets/services/pool-mid.jpg"
                    alt="Pool"
                  />
                </figure>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('SharedZones')">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <p>
                  {{ $t("SharedZonesDesc1") }}
                </p>
                <ul>
                  <li>{{ $t("RecreationGameRoom") }}</li>
                  <li>{{ $t("ChildrenPlayground") }}</li>
                  <li>{{ $t("SharedLoungeArea") }}</li>
                </ul>
                <p>
                  {{ $t("SharedZonesDesc2") }}
                  <br />
                  {{ $t("SharedZonesDesc3") }}
                </p>
              </div>
              <div class="col-lg-6">
                <figure>
                  <img
                    class="img-thumbnail"
                    src="../assets/services/shared-mid.jpg"
                    alt="Shared Zones"
                  />
                </figure>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>