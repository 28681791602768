<template>
  <section class="gallery-page my-5">
    <div class="gallery">
      <VueGallery
        :images="images"
        :index="index"
        @close="index = null"
      ></VueGallery>
      <div
        class="image"
        v-for="(image, imageIndex) in images"
        :key="imageIndex"
        @click="index = imageIndex"
        :style="{
          backgroundImage: 'url(' + image + ')',
          width: '300px',
          height: '200px',
        }"
      ></div>
    </div>
  </section>
</template>
<script>
import VueGallery from "vue-gallery";
export default {
  data: function () {
    return {
      images: [
        require("../assets/gallery/general/gallery-1.jpg"),
        require("../assets/gallery/general/gallery-2.jpg"),
        require("../assets/gallery/general/gallery-3.jpg"),
        require("../assets/gallery/general/gallery-4.jpg"),
        require("../assets/gallery/general/gallery-5.jpg"),
        require("../assets/gallery/general/gallery-6.jpg"),
        require("../assets/gallery/general/gallery-7.jpg"),
        require("../assets/gallery/general/gallery-8.jpg"),
        require("../assets/gallery/general/gallery-9.jpg"),
        require("../assets/gallery/general/gallery-10.jpg"),
        require("../assets/gallery/general/gallery-11.jpg"),
        require("../assets/gallery/general/gallery-12.jpg"),
        require("../assets/gallery/general/gallery-13.jpg"),
        require("../assets/gallery/general/gallery-14.jpg"),
        require("../assets/gallery/general/gallery-15.jpg"),
        require("../assets/gallery/general/gallery-16.jpg"),
        require("../assets/gallery/general/gallery-17.jpg"),
        require("../assets/gallery/general/gallery-18.jpg"),
        require("../assets/gallery/general/gallery-19.jpg"),
        require("../assets/gallery/general/gallery-20.jpg"),
        require("../assets/gallery/general/gallery-21.jpg"),
        require("../assets/gallery/general/gallery-22.jpg"),
        require("../assets/gallery/general/gallery-23.jpg"),
        require("../assets/gallery/general/gallery-24.jpg"),
        require("../assets/gallery/general/gallery-25.jpg"),
        require("../assets/gallery/general/gallery-26.jpg"),
        require("../assets/gallery/general/gallery-27.jpg"),
      ],
      index: null,
    };
  },

  components: {
    VueGallery,
  },
};
</script> 