<template>
  <main class="main">
    <!-- main section -->
    <section class="main-section">
      <div class="main-top">
        <div class="container-fluid">
          <div class="row">
            <div class="col-11">
              <a
                href="https://antalyasuites.com/"
                target="_blank"
                :title="$t('OneClickRes')"
                >{{ $t("OneClickRes") }} <i class="fa fa-hand"></i
              ></a>
            </div>
            <div class="col-1 logo">
              <img
                src="../assets/logos/antalya-suites.png"
                alt="Antalya Suites Logo"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="main-content container-fluid">
        <VueperSlides
          autoplay
          :bullets="false"
          :lazy="true"
          :touchable="false"
          class="no-shadow vueper-slide"
        >
          <VueperSlide
            class="slide-item"
            v-for="(slide, i) in mainSlider"
            :key="i"
            :image="slide.image"
          >
          </VueperSlide>
        </VueperSlides>
      </div>
    </section>
    <!-- end main section -->

    <!-- Res Datepicker Wrapper -->
    <ResDatepicker />
    <!-- Res Datepicker Wrapper -->

    <!-- res bottom side -->
    <div class="container res-bottom">
      <div class="row">
        <div class="col-lg-4">
          <div class="top">
            <img src="../assets/icons/building.png" alt="" />
            <h3>{{ $t("Apartment") }}</h3>
          </div>
          <div>
            <p>
              {{ $t("ApartmentDesc") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="top">
            <img src="../assets/icons/prize.png" alt="" />
            <h3>{{ $t("Services") }}</h3>
          </div>
          <div>
            <p>
              {{ $t("ServicesDesc") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="top">
            <img src="../assets/icons/location.png" alt="" />
            <h3>{{ $t("Location") }}</h3>
          </div>
          <div>
            <p>
              {{ $t("LocationDesc") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- res bottom side -->

    <!-- reservation section wrapper -->
    <section class="container-fluid reservation-section">
      <reservationWrapper />
    </section>
    <!-- end reservation section wrapper -->

    <!-- rooms tab start -->
    <section id="rooms" class="tab-side container-fluid">
      <div class="container text-center my-5">
        <h2>{{ $t("RoomsGallery") }}</h2>
        <p>
          {{ $t("RoomsDesc") }}
        </p>
      </div>
      <div class="container-fluid tabs-bg">
        <div class="container">
          <b-tabs content-class="mt-1">
            <!-- 3+1 tab -->
            <b-tab>
              <!-- slider -->
              <VueperSlides
                :bullets="false"
                autoplay
                :gap="5"
                :lazy="true"
                :slideMultiple="true"
                :slide-ratio="1 / 4"
                class="no-shadow vueper-slide"
                :visible-slides="3"
                :dragging-distance="70"
                :breakpoints="{ 800: { visibleSlides: 3, slideMultiple: 3 } }"
              >
                <VueperSlide
                  class="slide-item"
                  v-for="(slide, i) in roomGallery"
                  :key="i"
                  :image="slide.image"
                >
                </VueperSlide>
              </VueperSlides>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </section>
    <!-- rooms tabs end -->

    <!-- our services start -->
    <section id="our-services" class="container services-section mt-5">
      <div class="container-fluid text-center my-5 desc">
        <h2>{{ $t("Services") }}</h2>
        <p>
          {{ $t("ServicesDesc") }}
        </p>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div class="card-item">
            <figure>
              <img src="../assets/services/pool.jpg" :alt="$t('Pools')" />
            </figure>
            <h3>{{ $t("Pools") }}</h3>
            <p>- {{ $t("TwoOutdoorPools") }}</p>
            <p>- {{ $t("IndoorPool") }}</p>
            <p>- {{ $t("SunbedArea") }}</p>
            <p>- {{ $t("Aquapark") }}</p>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="card-item">
            <figure>
              <img src="../assets/services/spa.jpg" :alt="$t('spa')" />
            </figure>
            <h3>{{ $t("Spa Center") }}</h3>
            <p>- {{ $t("TurkishBath") }}</p>
            <p>- {{ $t("SaunaSteam") }}</p>
            <p>- {{ $t("VIPMassageRooms") }}</p>
            <p>- {{ $t("RelaxtionLounge") }}</p>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card-item">
            <figure>
              <img src="../assets/services/fitness.jpg" :alt="$t('fitness')" />
            </figure>
            <h3>{{ $t("Fitness And Wellnes") }}</h3>
            <p>- {{ $t("FitnessCenter") }}</p>
            <p>- {{ $t("CardioCycling") }}</p>
            <p>- {{ $t("NutritionBar") }}</p>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card-item">
            <figure>
              <img src="../assets/services/shared.jpg" :alt="$t('shared')" />
            </figure>
            <h3>{{ $t("Shared Zones") }}</h3>
            <p>- {{ $t("RecreationGameRoom") }}</p>
            <p>- {{ $t("ChildrenPlayground") }}</p>
            <p>- {{ $t("SharedLoungeArea") }}</p>
          </div>
        </div>
      </div>
      <div class="text-center my-4">
        <router-link to="/Services" tag="a" title="Explore all services"
          >{{ $t("ExploreAllServices") }} <i class="fa fa-arrow-right"></i
        ></router-link>
      </div>
    </section>
    <!-- our services end -->

    <!-- Testimonial section start -->
    <testimonialComp />
    <!-- Testimonial section end -->
  </main>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import reservationWrapper from "@/components/Reservation-Wrapper.vue";
import testimonialComp from "@/components/Testimonial-Comp.vue";
import ResDatepicker from "@/components/Res-Datepicker.vue";

export default {
  components: {
    VueperSlides,
    VueperSlide,
    reservationWrapper,
    testimonialComp,
    ResDatepicker,
  },
  data() {
    return {
      roomsVisibleSlides: null,
      testimonialsVisibleSlides: 3,
      mainSlider: [
        {
          image: require("@/assets/gallery/main-slider/sirius-1.jpg"),
        },
        {
          image: require("@/assets/gallery/main-slider/sirius-2.jpg"),
        },
        {
          image: require("@/assets/gallery/main-slider/sirius-3.jpg"),
        },
        {
          image: require("@/assets/gallery/main-slider/sirius-4.jpg"),
        },
      ],
      homeGallery: [
        {
          image: require("@/assets/gallery/sirius-17.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-18.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-19.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-9.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-11.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-12.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-1.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-2.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-4.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-10.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-5.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-6.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-7.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-8.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-13.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-14.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-15.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-16.jpg"),
        },
        {
          image: require("@/assets/gallery/sirius-20.jpg"),
        },
      ],
      roomGallery: [
        {
          image: require("@/assets/gallery/room/room-1.jpg"),
        },
        {
          image: require("@/assets/gallery/room/room-5.jpg"),
        },
        {
          image: require("@/assets/gallery/room/room-3.jpg"),
        },
        {
          image: require("@/assets/gallery/room/room-4.jpg"),
        },
        {
          image: require("@/assets/gallery/room/room-2.jpg"),
        },
        {
          image: require("@/assets/gallery/room/room-6.jpg"),
        },
        {
          image: require("@/assets/gallery/room/room-7.jpg"),
        },
        {
          image: require("@/assets/gallery/room/room-8.jpg"),
        },
        {
          image: require("@/assets/gallery/room/room-9.jpg"),
        },
        {
          image: require("@/assets/gallery/room/room-10.jpg"),
        },
        {
          image: require("@/assets/gallery/room/room-11.jpg"),
        },
        {
          image: require("@/assets/gallery/room/room-12.jpg"),
        },
        {
          image: require("@/assets/gallery/room/room-13.jpg"),
        },
      ],
    };
  },
  created() {
    if (window.innerWidth < 997) {
      this.roomsVisibleSlides = 1;
    } else {
      this.roomsVisibleSlides = 3;
    }
  },
};
</script>
