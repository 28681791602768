import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// initialize route pages
import Home from '../Views/Home-Page.vue'
import Services from '../Views/Services-Page.vue'
import Contact from '../Views/Contact-Page.vue'
import Location from '../Views/Location-Page.vue'
import Gallery from '../Views/Gallery-Page.vue'
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/Services',
        name: 'Services',
        component: Services
    },
    {
        path: '/Contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/Location',
        name: 'Location',
        component: Location
    },
    {
        path: '/Gallery',
        name: 'Gallery',
        component: Gallery
    }
]
const router = new VueRouter({
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        };
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    //  document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`;
    document.title = `${process.env.VUE_APP_TITLE}`;
    next();
});

export default router