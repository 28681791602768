<template>
  <section class="res-section">
    <div class="container-fluid w-100 text-center">
      <!-- <div class="col-lg-2">
      <date-picker
        range
        v-model="selectedDate"
        :disabledDates="disabledDateFilter"
        @input="selectedDates"
      ></date-picker> 
     </div>-->
      <div class="container">
        <div class="row main">
          <div class="col-lg-4">
            <span>{{ $t("Destination") }}</span>
            <p>Sirius Town (Kundu)</p>
          </div>
          <div class="col-lg-2">
            <button>
              <a
                href="https://antalyasuites.com"
                title="Antalya Suites"
                target="_blank"
                class="text-white"
              >
                <i class="fa fa-search"></i>
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import DatePicker from "vue2-datepicker";
//import "vue2-datepicker/index.css";

export default {
  name: "Res-Datepicker",
  data() {
    return {
      //  value: "",
      //min: null,
      //max: null,
      //selectedDate: null, // Seçilen tarih
    };
  },
  components: {
    //DatePicker
  },
  created() {},
  methods: {
    //selectedDates(newValue, oldValue) {
    // console.log(newValue + oldValue);
    // Tarih değiştiğinde yapılacak işlemleri burada gerçekleştirebilirsiniz
    // },
    // disabledDateFilter(date) {
    //   console.log("burdayım");
    //   const today = new Date();
    //   return date < today;
    // },
  },
};
</script>
