import Vue from 'vue'
import App from './App.vue'
import router from './Router/index'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueI18n from 'vue-i18n';


Vue.use(VueI18n);

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false

// lang bileşeni
const i18n = new VueI18n({
  locale: 'en', // Varsayılan dilinizi burada belirtin
  fallbackLocale: 'en', // Kullanılamayan bir dil belirtildiğinde varsayılan olarak hangi dili kullanacağınızı belirtin
  messages: {
    en: require('./i18n/en.json'), // İngilizce çeviri dosyasını burada yükleyin
    tr: require('./i18n/tr.json') // Türkçe çeviri dosyasını burada yükleyin
  }
});
new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app')
