<template>
  <section class="contact-page">
    <!-- Contact us section start -->
    <ContactComp />
    <!-- Contact us section end -->
  </section>
</template>

<script>
import ContactComp from "@/components/Contact-Comp.vue";
export default {
  components: {
    ContactComp,
  },
};
</script>

<style>
</style>