<template>
  <!-- Testimonial Start -->
  <div class="container-xxl">
    <div class="container testimonial-section py-5">
      <div class="text-center desc">
        <h2>{{ $t("HappyGuests") }}</h2>
        <p>
          {{ $t("HappyGuestsDesc") }}
        </p>
      </div>
      <vueper-slides
        class="no-shadow"
        :visible-slides="3"
        :gap="3"
        :bullets="false"
        :slide-ratio="1 / 4"
        :dragging-distance="200"
        :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
      >
        <vueper-slide v-for="(comment, i) in slidesAll" :key="i">
          <template #content>
            <div
              class="vueperslide__content-wrapper"
              style="flex-direction: column"
            >
              <div>
                <!-- <i class="material-icons fa fa-user"></i> -->
                <div class="stars">
                  &#11088; &#11088; &#11088; &#11088; &#11088;
                </div>
                <p>{{ $t(comment.comment) }}</p>
                <h6 class="mt-3">
                  {{ $t(comment.name) }} <img :src="comment.profile" alt="" />
                </h6>
              </div>
            </div>
          </template>
        </vueper-slide>
      </vueper-slides>
    </div>
  </div>
  <!-- Testimonial End -->
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  components: {
    VueperSlides,
    VueperSlide,
  },
  data: () => ({
    slidesAll: [
      {
        name: "Serkan",
        profile: require("@/assets/gallery/sirius-1.jpg"),
        comment: "SerkanDesc",
      },
      {
        name: "Deniz",
        profile: require("@/assets/gallery/sirius-1.jpg"),
        comment: "DenizDesc",
      },
      {
        name: "Esra",
        profile: require("@/assets/gallery/sirius-1.jpg"),
        comment: "EsraDesc",
      },
      {
        name: "Baris",
        profile: require("@/assets/gallery/sirius-1.jpg"),
        comment: "BarisDesc",
      },
      {
        name: "Eric",
        profile: require("@/assets/gallery/sirius-1.jpg"),
        comment: "EricDesc",
      },
    ],
  }),
};
</script>

<style>
</style>