<template>
  <section id="contact-us" class="container contact-section my-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class="col">
            <div class="row">
              <div class="col-lg-6">
                <h2>{{ $t("ContactUs") }}</h2>
                <p>{{ $t("OurFriendlyTeam") }}</p>
              </div>
              <div class="col-lg-6">
                <img
                  src="@/assets/logos/antalya-suites.png"
                  alt="Antalya Suites Logo"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row mt-3">
              <div class="col-lg-6">
                <div class="contact-box">
                  <h3>{{ $t("ContactInfo") }}</h3>
                  <p>{{ $t("TalkToUs") }}</p>
                  <a href="tel:+905416265399">+90 541 626 53 99</a>
                  <br />
                  <a href="tel:+905365836399">+90 536 583 63 99</a>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="contact-box">
                  <h3>{{ $t("HostSupport") }}</h3>
                  <p>{{ $t("QuicklyIdentify") }}</p>
                  <a href="mailto:info@antalyasuites.com" title="Antalya Suites"
                    >info@antalyasuites.com</a
                  >
                </div>
              </div>
              <div class="col-lg-6"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10737.962491972543!2d30.86380071787343!3d36.85647267974239!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c382c0ad4bfff1%3A0x5e83ae2f5701c8cd!2sSirius%20Town!5e0!3m2!1sen!2str!4v1695662107867!5m2!1sen!2str"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>