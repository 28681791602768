var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"location-section my-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-6"},[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t("LocationTitle")))]),_c('p',[_vm._v(_vm._s(_vm.$t("LocationDesc1")))]),_c('p',[_vm._v(_vm._s(_vm.$t("LocationDesc2")))]),_c('p',[_vm._v(_vm._s(_vm.$t("LocationDesc3")))]),_c('p',[_vm._v(_vm._s(_vm.$t("LocationDesc4")))])])])]),_c('div',{staticClass:"boxes-side mt-5 text-center"},[_c('h3',[_vm._v(_vm._s(_vm.$t("LocationAdvantages")))]),_c('div',{staticClass:"boxes"},[_c('div',{staticClass:"box"},[_vm._m(1),_c('p',[_vm._v(_vm._s(_vm.$t("Mall")))])]),_c('div',{staticClass:"box"},[_vm._m(2),_c('p',[_vm._v(_vm._s(_vm.$t("School")))])]),_c('div',{staticClass:"box"},[_vm._m(3),_c('p',[_vm._v(_vm._s(_vm.$t("BusStop")))])]),_c('div',{staticClass:"box"},[_vm._m(4),_c('p',[_vm._v(_vm._s(_vm.$t("Hospital")))])]),_c('div',{staticClass:"box"},[_vm._m(5),_c('p',[_vm._v(_vm._s(_vm.$t("Restaurants")))])]),_c('div',{staticClass:"box"},[_vm._m(6),_c('p',[_vm._v(_vm._s(_vm.$t("Sea")))])]),_c('div',{staticClass:"box"},[_vm._m(7),_c('p',[_vm._v(_vm._s(_vm.$t("Airport")))])]),_c('div',{staticClass:"box"},[_vm._m(8),_c('p',[_vm._v(_vm._s(_vm.$t("Banks")))])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6"},[_c('figure',[_c('img',{staticClass:"img-thumbnail",attrs:{"src":require("../assets/location.jpg"),"alt":"sirius town location"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',[_c('img',{attrs:{"src":require("../assets/icons/location-01.png"),"alt":"location"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',[_c('img',{attrs:{"src":require("../assets/icons/location-05.png"),"alt":"location"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',[_c('img',{attrs:{"src":require("../assets/icons/location-09.png"),"alt":"location"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',[_c('img',{attrs:{"src":require("../assets/icons/location-07.png"),"alt":"location"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',[_c('img',{attrs:{"src":require("../assets/icons/location-03.png"),"alt":"location"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',[_c('img',{attrs:{"src":require("../assets/icons/location-10.png"),"alt":"location"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',[_c('img',{attrs:{"src":require("../assets/icons/location-04.png"),"alt":"location"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',[_c('img',{attrs:{"src":require("../assets/icons/location-06.png"),"alt":"location"}})])
}]

export { render, staticRenderFns }